import { SupportedLanguages } from "@config/translation-config";
import { createContext, useContext } from "react";

const LocaleContext = createContext<SupportedLanguages>("cs");

export const useLocale = () => useContext(LocaleContext);

export const LocaleProvider = LocaleContext.Provider;

export type Locale = "cs" | "en";
